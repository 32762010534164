import {
  FunctionalColorConfig,
  FunctionalColorToken,
  setTheme,
} from '@robot/ui-theming';

const colorFillContentHover = {
  dark: '#48494b',
  light: '#EEF1F9',
};
const colorDivider = {
  dark: '#14192a',
  light: '#f1f1f6',
};
const colorTextSubdued = {
  dark: '#9597a7',
  light: 'rgba(175, 184, 208, 1)',
};
const colorTextDisabled = {
  dark: '#434360',
  light: 'rgba(175, 184, 208, 1)',
};
const colorTextCritical = {
  dark: '#FF6966',
  light: '#E16161',
};
const colorTextSuccess = {
  dark: '#66ffa4',
  light: '#1BBC22',
};
const colorTextWarning = {
  dark: '#fbbf24',
  light: '#F49300',
};
const colorTextHighlight = {
  dark: '#71ceff',
  light: '#03BBBB',
};
const colorTextDecorative = {
  dark: '#71ceff',
  light: '#05C0C0',
};
const colorTextLightSolid = {
  dark: '#ffffff',
  light: '#717992',
};
const colorIcon = {
  dark: '#9597a7',
  light: '#AFB8D0',
};
const colorIconSuccess = {
  dark: '#22c55e',
  light: '#1BBC22',
};
const colorIconWarning = {
  dark: '#fbbf24',
  light: '#fbbf24',
};
const colorIconCritical = {
  dark: '#f86868',
  light: '#E16161',
};
const colorIconHighlight = {
  dark: '#8293f8',
  light: '#626cf1',
};
const colorIconHover = {
  dark: 'rgba(255, 255, 255, 0.1)',
  light: '#EEF1F9',
};
const colorSurfaceSubdued = {
  dark: '#1A1B1E',
  light: '#f8f9fc',
};
const colorSurfaceSelected = {
  dark: '#5BA6CE30',
  light: '#f0f3ff',
};
const colorSurfaceHover = {
  dark: '#f1f1f6',
  light: '#272744',
};
const colorInteractive = {
  dark: '#71ceff',
  light: '#626cf1',
};
const colorBgHeader = {
  dark: '#080809',
  light: '#ffffff',
};
const colorHeaderMenu = {
  dark: '#141929',
  light: '#F7F7F7',
};
const colorHeaderMenuActive = {
  dark: '#71CEFF',
  light: '',
};
const colorFormBg = {
  dark: 'rgba(178, 221, 254, 0.5)',
  light: '#FFFFFF',
};
const colorBgFooter = {
  dark: '#101112',
  light: '#E2E6F3',
};
const colorBgDecorative = {
  dark: 'rgba(113, 206, 255, 0.08)',
  light: '#DBF7F7',
};
export const functionalColorConfig: FunctionalColorConfig = {
  colorInteractive,
  colorFillContentHover,
  colorDivider,
  colorTextSubdued,
  colorTextDisabled,
  colorTextSuccess,
  colorTextWarning,
  colorTextCritical,
  colorTextHighlight,
  colorTextDecorative,
  colorTextLightSolid,
  colorIcon,
  colorIconSuccess,
  colorIconWarning,
  colorIconCritical,
  colorIconHover,
  colorIconHighlight,
  colorSurfaceSubdued,
  colorSurfaceSelected,
  colorSurfaceHover,
  colorBgHeader,
  colorHeaderMenu,
  colorHeaderMenuActive,
  colorFormBg,
  colorBgFooter,
  colorBgDecorative,
};

export const functionalColorToken: FunctionalColorToken = {
  colorFillContentHover: setTheme(colorFillContentHover),
  colorDivider: setTheme(colorDivider),
  colorTextSubdued: setTheme(colorTextSubdued),
  colorTextDisabled: setTheme(colorTextDisabled),
  colorTextSuccess: setTheme(colorTextSuccess),
  colorTextWarning: setTheme(colorTextWarning),
  colorTextCritical: setTheme(colorTextCritical),
  colorTextHighlight: setTheme(colorTextHighlight),
  colorTextDecorative: setTheme(colorTextDecorative),
  colorTextLightSolid: setTheme(colorTextLightSolid),
  colorIcon: setTheme(colorIcon),
  colorIconSuccess: setTheme(colorIconSuccess),
  colorIconWarning: setTheme(colorIconWarning),
  colorIconCritical: setTheme(colorIconCritical),
  colorIconHover: setTheme(colorIconHover),
  colorIconHighlight: setTheme(colorIconHighlight),
  colorSurfaceSubdued: setTheme(colorSurfaceSubdued),
  colorSurfaceSelected: setTheme(colorSurfaceSelected),
  colorSurfaceHover: setTheme(colorSurfaceHover),
  colorInteractive: setTheme(colorInteractive),
  colorBgHeader: setTheme(colorBgHeader),
  colorHeaderMenu: setTheme(colorHeaderMenu),
  colorHeaderMenuActive: setTheme(colorHeaderMenuActive),
  colorFormBg: setTheme(colorFormBg),
  colorBgFooter: setTheme(colorBgFooter),
  colorBgDecorative: setTheme(colorBgDecorative),
};
