import { globalToken } from '@robot/ui-theming';
import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
} from 'antd';
import { ButtonType } from 'antd/es/button';
import 'antd/es/button/style';
import { CSSProperties } from 'react';
import styled from 'styled-components';

const { transitionSlow, borderRadiusLG } = globalToken;
const {
  opacityButtonDisabled,
  borderWidthButton,
  heightButtonSM,
  heightButtonLG,
  paddingHorizontalButtonSM,
  paddingHorizontalButtonLG,
  colorButtonText,
  colorButtonTextDisabled,
  colorButtonPrimaryBorder,
  colorButtonPrimaryBgHover,
  colorButtonPrimaryBg,
  colorButtonPrimaryBgActive,
  colorButtonPrimaryBgLoading,
  colorButtonPrimaryBgDisabled,
  colorButtonPrimaryText,
  colorButtonGhostTextActive,
  colorButtonGhostTextDisabled,
  colorButtonGhostBorder,
  colorButtonGhostBorderHover,
  colorButtonGhostBgHover,
  colorButtonGhostBorderActive,
  colorButtonGhostBorderLoading,
  colorButtonGhostBg,
  colorButtonDefaultTextActive,
  colorButtonDefaultTextDisabled,
  colorButtonDefaultBg,
  colorButtonDefaultBgHover,
  colorButtonDefaultBorderLoading,
  colorButtonDefaultBorder,
  colorButtonDefaultBorderHover,
  colorButtonDefaultBorderActive,
  colorButtonSuccessText,
  colorButtonSuccessTextDisabled,
  colorButtonSuccessTextActive,
  colorButtonSuccessBg,
  colorButtonSuccessBgHover,
  colorButtonSuccessBgActive,
  colorButtonSuccessBorderLoading,
  colorButtonSuccessBorder,
  colorButtonSuccessBorderHover,
  colorButtonSuccessBorderActive,
  colorButtonCriticalText,
  colorButtonCriticalTextDisabled,
  colorButtonCriticalTextActive,
  colorButtonCriticalBg,
  colorButtonCriticalBgHover,
  colorButtonCriticalBgActive,
  colorButtonCriticalBorderLoading,
  colorButtonCriticalBorder,
  colorButtonCriticalBorderHover,
  colorButtonCriticalBorderActive,
  colorButtonLinkBg,
  colorButtonLinkBgHover,
  colorButtonTextActive,
} = globalToken.Button;

export const Button: React.FC<
  Omit<AntdButtonProps, 'type'> & {
    containerStyle?: CSSProperties;
    type?: ButtonType | 'success' | 'critical';
  }
> = ({ className, containerStyle, type, ...props }) => (
  <ButtonContainer className={className} style={containerStyle}>
    <AntdButton
      {...props}
      className={`ant-btn-${type}`}
      type={type === 'success' || type === 'critical' ? undefined : type}
    />
  </ButtonContainer>
);

const ButtonContainer = styled.span`
  display: flex;
  justify-content: center;
  width: 100%;
  .ant-btn {
    border-radius: ${borderRadiusLG}px !important;
    border-width: ${borderWidthButton} !important;
    height: ${heightButtonLG}px;
    padding-left: ${paddingHorizontalButtonLG}px;
    padding-right: ${paddingHorizontalButtonLG}px;
    width: 100%;
    transition: all ${transitionSlow};
    font-weight: 500;
    font-size: 15px;
    * > svg {
      vertical-align: -1px !important;
    }

    &.ant-btn-sm {
      height: ${heightButtonSM}px;
      padding-left: ${paddingHorizontalButtonSM}px;
      padding-right: ${paddingHorizontalButtonSM}px;
    }

    &.ant-btn-primary {
      border: ${colorButtonPrimaryBorder};
      background: ${colorButtonPrimaryBg};
      color: ${colorButtonPrimaryText};

      &:hover:not(.ant-btn-loading, :disabled) {
        background: ${colorButtonPrimaryBgHover};
        color: ${colorButtonPrimaryText};
      }

      &:focus:not(.ant-btn-loading, :disabled) {
        background: ${colorButtonPrimaryBgActive};
        color: ${colorButtonPrimaryText};
      }

      &:active:not(.ant-btn-loading, :disabled) {
        background: ${colorButtonPrimaryBgActive};
        color: ${colorButtonPrimaryText};
      }

      &.ant-btn-loading {
        border-color: ${colorButtonPrimaryBgLoading};
        color: ${colorButtonPrimaryText};
      }

      &:disabled {
        :hover {
          background: ${colorButtonPrimaryBgDisabled};
        }
        background: ${colorButtonPrimaryBgDisabled};
        color: ${colorButtonPrimaryText};
      }
    }

    &.ant-btn-ghost {
      border-color: ${colorButtonGhostBorder};
      background: ${colorButtonGhostBg};
      color: ${colorButtonText};

      &:hover:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonGhostBorderHover};
        background: ${colorButtonGhostBgHover};
        color: ${colorButtonText};
      }

      &.ant-btn-loading&::before {
        background: ${colorButtonGhostBg};
      }

      &.ant-btn-loading {
        border-color: ${colorButtonGhostBorderLoading};
        color: ${colorButtonText};
      }

      &:active:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonGhostBorderActive};
        color: ${colorButtonGhostTextActive};
      }

      &:active.ant-btn-loading {
        border-color: ${colorButtonGhostBorderLoading};
        background: ${colorButtonGhostBg};
        color: ${colorButtonText};
      }

      &:disabled {
        border-color: ${colorButtonGhostBorder};
        background: ${colorButtonGhostBg};
        color: ${colorButtonGhostTextDisabled};
        cursor: not-allowed;
      }
    }

    &.ant-btn-default {
      border-color: ${colorButtonDefaultBorder};
      background: ${colorButtonDefaultBg};
      color: ${colorButtonText};

      &:hover:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonDefaultBorderHover};
        background: ${colorButtonDefaultBgHover};
        color: ${colorButtonText};
      }

      &.ant-btn-loading&::before {
        background: ${colorButtonDefaultBg};
      }

      &.ant-btn-loading {
        border-color: ${colorButtonDefaultBorderLoading};
        color: ${colorButtonText};
      }

      &:active:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonDefaultBorderActive};
        background-color: ${colorButtonDefaultBg};
        color: ${colorButtonDefaultTextActive};
      }

      &:active.ant-btn-loading {
        border-color: ${colorButtonDefaultBorderLoading};
        background: ${colorButtonDefaultBg};
        color: ${colorButtonText};
      }

      &:disabled {
        border-color: ${colorButtonDefaultBorder};
        background: ${colorButtonDefaultBg};
        color: ${colorButtonDefaultTextDisabled};
        cursor: not-allowed;
      }
    }

    &.ant-btn-success {
      border-color: ${colorButtonSuccessBorder};
      background: ${colorButtonSuccessBg};
      color: ${colorButtonSuccessText};

      &:hover:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonSuccessBorderHover};
        background: ${colorButtonSuccessBgHover};
        color: ${colorButtonSuccessText};
      }

      &.ant-btn-loading&::before {
        background: ${colorButtonSuccessBg};
      }

      &.ant-btn-loading {
        border-color: ${colorButtonSuccessBorder};
        background-color: ${colorButtonSuccessBg};
        color: ${colorButtonSuccessText};
      }

      &:active:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonSuccessBorderActive};
        background-color: ${colorButtonSuccessBgActive};
        color: ${colorButtonSuccessTextActive};
      }

      &:active.ant-btn-loading {
        border-color: ${colorButtonSuccessBorderLoading};
        background: ${colorButtonSuccessBgActive};
        color: ${colorButtonSuccessTextActive};
      }

      &:disabled {
        opacity: ${opacityButtonDisabled};
        border-color: ${colorButtonSuccessBorder};
        background: ${colorButtonSuccessBg};
        color: ${colorButtonSuccessTextDisabled};
        cursor: not-allowed;
      }
    }

    &.ant-btn-critical {
      border-color: ${colorButtonCriticalBorder};
      background: ${colorButtonCriticalBg};
      color: ${colorButtonCriticalText};

      &:hover:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonCriticalBorderHover};
        background: ${colorButtonCriticalBgHover};
        color: ${colorButtonCriticalText};
      }

      &.ant-btn-loading&::before {
        background: ${colorButtonCriticalBg};
      }

      &.ant-btn-loading {
        border-color: ${colorButtonCriticalBorder};
        background-color: ${colorButtonCriticalBg};
        color: ${colorButtonCriticalText};
      }

      &:active:not(.ant-btn-loading, :disabled) {
        border-color: ${colorButtonCriticalBorderActive};
        background-color: ${colorButtonCriticalBgActive};
        color: ${colorButtonCriticalTextActive};
      }

      &:active.ant-btn-loading {
        border-color: ${colorButtonCriticalBorderLoading};
        background: ${colorButtonCriticalBgActive};
        color: ${colorButtonCriticalTextActive};
      }

      &:disabled {
        opacity: ${opacityButtonDisabled};
        border-color: ${colorButtonCriticalBorder};
        background: ${colorButtonCriticalBg};
        color: ${colorButtonCriticalTextDisabled};
        cursor: not-allowed;
      }
    }

    &.ant-btn-link {
      color: ${colorButtonText};

      &:hover:not(.ant-btn-loading) {
        color: ${colorButtonText};
        background: ${colorButtonLinkBgHover};
      }

      &:active:not(.ant-btn-loading) {
        background: ${colorButtonLinkBg};
        color: ${colorButtonTextActive};
      }

      &.ant-btn-loading&::before {
        background: ${colorButtonLinkBg};
      }

      &:disabled {
        color: ${colorButtonTextDisabled};
      }

      &:disabled&:hover {
        background: ${colorButtonLinkBg};
        color: ${colorButtonTextDisabled};
      }

      &:disabled&:active {
        background: ${colorButtonLinkBg};
        color: ${colorButtonTextDisabled};
      }
    }
  }
`;
