import {
  NeutralColorConfig,
  NeutralColorToken,
  setTheme,
} from '@robot/ui-theming';

const colorTextBase = {
  dark: '#e6e6ef',
  light: '#1F2024',
};
const colorText = {
  dark: '#ffffff',
  light: '#1F2024',
};
const colorTextSecondary = {
  dark: '#adb8d8',
  light: '#717992',
};
const colorTextTertiary = {
  dark: '#737b94',
  light: '#717992',
};
const colorTextQuaternary = {
  dark: '#737b94',
  light: '#AFB8D0',
};
const colorBgDefault = {
  dark: '#080809',
  light: '#FFFFFF',
};
const colorBgBase = {
  dark: '#080809',
  light: '#EDF0FA',
};
const colorBg = {
  dark: '#0F131F',
  light: '#FFFFFF',
};
const colorBgSecondary = {
  dark: '#2B2D33',
  light: '#F6F7FC',
};
const colorBgTertiary = {
  dark: '#313235',
  light: '#EEF0F6',
};
const colorBgQuaternary = {
  dark: '#313235',
  light: '#E7EBF6',
};
const colorBgContainer = {
  dark: '#1A1B1E',
  light: '#FFFFFF',
};
const colorBgSpotlight = {
  dark: '#1A1B1E',
  light: '#F6F7FC',
};
const colorBgElevated = {
  dark: '#1A1B1E',
  light: '#FFFFFF',
};
const colorBgElevatedContent = {
  dark: '#1A1B1E',
  light: '#E5E8F3',
};
const colorBgMask = {
  dark: '#0A0C14',
  light: '#E5E8F3',
};
const colorBgOverlay = {
  dark: '#00000000',
  light: 'rgba(36, 43, 63, 0.8)',
};
const colorBgDisabled = {
  light: 'rgba(113, 121, 146, 0.12)',
  dark: 'rgba(31, 32, 36, 1)',
};
const colorBorder = {
  dark: '#141929',
  light: '#D4D9EA',
};
const colorBorderSecondary = {
  dark: 'rgba(66, 66, 66, 0.1)',
  light: 'rgba(96, 113, 168, 0.15)',
};
const colorBorderTertiary = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.35)',
};
const colorBorderAdditional = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.8)',
};
const colorBorderDisabled = {
  dark: '#0a0a19',
  light: 'rgba(96, 113, 168, 0.35)',
};

export const neutralColorConfig: NeutralColorConfig = {
  colorTextBase,
  colorText,
  colorTextSecondary,
  colorTextTertiary,
  colorTextQuaternary,
  colorBgDefault,
  colorBgBase,
  colorBgContainer,
  colorBgSpotlight,
  colorBgElevated,
  colorBgElevatedContent,
  colorBgDisabled,
  colorBgOverlay,
  colorBg,
  colorBgSecondary,
  colorBgTertiary,
  colorBgQuaternary,
  colorBgMask,
  colorBorder,
  colorBorderDisabled,
  colorBorderSecondary,
  colorBorderTertiary,
  colorBorderAdditional,
};

export const neutralColorTokens: NeutralColorToken = {
  colorTextBase: setTheme(colorTextBase),
  colorText: setTheme(colorText),
  colorTextSecondary: setTheme(colorTextSecondary),
  colorTextTertiary: setTheme(colorTextTertiary),
  colorTextQuaternary: setTheme(colorTextQuaternary),
  colorBgDefault: setTheme(colorBgDefault),
  colorBgBase: setTheme(colorBgBase),
  colorBg: setTheme(colorBg),
  colorBgSecondary: setTheme(colorBgSecondary),
  colorBgTertiary: setTheme(colorBgTertiary),
  colorBgQuaternary: setTheme(colorBgQuaternary),
  colorBgContainer: setTheme(colorBgContainer),
  colorBgSpotlight: setTheme(colorBgSpotlight),
  colorBgElevated: setTheme(colorBgElevated),
  colorBgOverlay: setTheme(colorBgOverlay),
  colorBgElevatedContent: setTheme(colorBgElevatedContent),
  colorBgMask: setTheme(colorBgMask),
  colorBgDisabled: setTheme(colorBgDisabled),
  colorBorder: setTheme(colorBorder),
  colorBorderSecondary: setTheme(colorBorderSecondary),
  colorBorderTertiary: setTheme(colorBorderTertiary),
  colorBorderAdditional: setTheme(colorBorderAdditional),
  colorBorderDisabled: setTheme(colorBorderDisabled),
};
